// extracted by mini-css-extract-plugin
export var center = "styles-module--center--wPfNz";
export var modified = "styles-module--modified--4eqAV";
export var swiper = "styles-module--swiper--IeZ--";
export var swiper3d = "styles-module--swiper-3d--A1HQF";
export var swiperAndroid = "styles-module--swiper-android--Tu-FP";
export var swiperAutoheight = "styles-module--swiper-autoheight--DOa94";
export var swiperBackfaceHidden = "styles-module--swiper-backface-hidden--FPhrO";
export var swiperCentered = "styles-module--swiper-centered--QXAwO";
export var swiperCssMode = "styles-module--swiper-css-mode--0+OFr";
export var swiperCubeShadow = "styles-module--swiper-cube-shadow--x4vqn";
export var swiperHorizontal = "styles-module--swiper-horizontal--XUo-R";
export var swiperPointerEvents = "styles-module--swiper-pointer-events--nPe5z";
export var swiperSlide = "styles-module--swiper-slide--sfQm+";
export var swiperSlideInvisibleBlank = "styles-module--swiper-slide-invisible-blank--DY1Ec";
export var swiperSlideShadow = "styles-module--swiper-slide-shadow--vxUlc";
export var swiperSlideShadowBottom = "styles-module--swiper-slide-shadow-bottom--FgGs0";
export var swiperSlideShadowLeft = "styles-module--swiper-slide-shadow-left--gnZbJ";
export var swiperSlideShadowRight = "styles-module--swiper-slide-shadow-right--4xTw-";
export var swiperSlideShadowTop = "styles-module--swiper-slide-shadow-top--348mZ";
export var swiperVertical = "styles-module--swiper-vertical--afLmp";
export var swiperWrapper = "styles-module--swiper-wrapper--dsfod";