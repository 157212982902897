// import './src/styles/styles.module.scss';
require('./src/styles/styles.module.scss');

exports.onRouteUpdate = () => {
  // Remove existing script if it exists
  const existingScript = document.querySelector("script[src='/assets/js/accordions.js']");
  if (existingScript) {
    existingScript.remove();
  }

  console.log('page changed');

  // Add the script
  const script = document.createElement('script');
  script.src = '/assets/js/accordions.js';
  script.async = true;
  document.body.appendChild(script);
};